import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReserveSuccess from './success';
import TrialStart from './trial_start';
import SetPassword from './set_password';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import TagManager from 'react-gtm-module'

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "success/",
        element: <ReserveSuccess />,
    },
    {
        path: "set_password/",
        element: <SetPassword />,
    },
    {
        path: "trial_start/",
        element: <TrialStart />,
    },
]);

const tagManagerArgs = {
    gtmId: 'GTM-KDD3K7J'
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
