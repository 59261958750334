import * as React from "react";
import styled from "styled-components";

interface DialogProps {
    hide: () => void;
    children?: React.ReactNode;
}

const StyledDialogBackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledDialogCard = styled.div`
    background-color: #FFFFFF;
    max-width: 640px;
    width: 100%;
    padding: 25px;
    margin: 0 15px;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 100%;
    }

    p {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }

    .qr-code {
        width: 50%;
    }

    .drink-notif {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        margin: 10px;
        margin-top: 20px;
    }

    .drink-claim-details {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }
`;

export default function CouponDialog({ hide, children }: DialogProps) {
    return (
        <StyledDialogBackDrop onClick={hide}>
        <StyledDialogCard onClick={(e) => e.stopPropagation()}>
            {children}
        </StyledDialogCard>
        </StyledDialogBackDrop>
    );
}
