import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom"; 

import Page from "./components/Page";
import VersionDisplay from "./components/VersionDisplay";
import api from './api/api';

import {
    StyledHeader,
    StyledLogoContainer,
    StyledContent,
    StyledSuccessTitle,
    StyledSuccess,
    StyledForm,
    StyledButton,
    StyledCompanyInfo
} from "./styles/styles";

interface TrialDataProps {
    [key: string]: any;
}

export default function SetPassword() {

    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [trialFlowData, setTrialFlowData] = useState<TrialDataProps>({});
    const navigate = useNavigate();

    const memberHash = searchParams.get('mh') ?? "";

    useEffect(() => {

        async function verifyUserAndGetUserData() {

            const verifyUserResp = await api.verifyUserAndCheckPasswordInit(memberHash ?? "");

            if (verifyUserResp.statusCode === 1) {
                console.log("verifyUser error");
            }

            const hasPassword = verifyUserResp.result;
            if (hasPassword) {
                navigate({
                    pathname: '/trial_start',
                    search: createSearchParams({
                        mh : memberHash ?? "",
                    }).toString(),
                });
            }

            const resp = await api.getTrialFlowData(memberHash);

            if (resp.statusCode === 1) {
                setErrorMsg("無料体験の再予約がまだのようです。希望日時・店舗をカスタマーサポートまでお問い合わせください。");
                return;
            }

            resp.displayTime = resp.startTime + " - " + 
                (
                    resp.endTime && resp.endTime.split(' ').length > 0 ?
                        resp.endTime.split(' ')[1] :
                        resp.endTime
                );

            setTrialFlowData(resp);

            setIsLoading(false);
        }
        verifyUserAndGetUserData();
    }, []);

    const skipSetPassword = async () => {
        navigate({
            pathname: '/trial_start',
            search: createSearchParams({
                mh : memberHash ?? "",
            }).toString(),
        });
    };

    const requestSetPassword = async () => {

        const response = await api.setInitialPassword(password, memberHash);

        if (response.statusCode === 0) {
            navigate({
                pathname: '/trial_start',
                search: createSearchParams({
                    mh : memberHash ?? "",
                }).toString(),
            });
        }
    };

    return (
        <>
        <script src="//statics.a8.net/a8sales/a8sales.js"></script>

        <Page>
            <StyledHeader>
            <StyledLogoContainer>
                <a href="https://my-lancul.com/">
                <img src="/logo.png" alt="LanCulロゴ" height="40px" />
                </a>
            </StyledLogoContainer>
            </StyledHeader>

            {isLoading ? (

                errorMsg && errorMsg.length > 0 ? (
                    <>
                        <p style={{textAlign : 'center', margin : '20px'}}>{errorMsg}</p>
                        <StyledCompanyInfo>
                            <h3>お問い合わせ</h3>

                            <div>
                                <img src="/icon_faq.png" />
                                <a
                                    href="https://faq.lancul.com/knowledge"
                                    target="_blank"
                                    rel="noreferrer"
                                > 
                                        よくあるご質問
                                </a>
                            </div>
                        </StyledCompanyInfo>
                    </>
                ) : (
                    <div
                        className="loading"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginTop: "1.5rem",
                        }}
                        id="loading"
                    >
                    <img src="/spinner.gif" alt="Loading..." height="40px" />
                    <br />
                    <strong>しばらくお待ちください</strong>
                    </div>
                )
                ) : (
                    <>
                        <StyledContent>
                        <StyledSuccessTitle>
                            Password
                            <span>パスワード設定</span>
                        </StyledSuccessTitle>

                        <StyledSuccess>

                            <p>体験当日、チェックインを行うため、パスワード設定をお願いします。<br/>
                            設定完了して、英会話カフェ参加時に使える1ドリンク券をお受け取りください。</p>

                            <p>*パスワードは半角英数字で入力してください。</p>

                            <p style={{marginBottom: "-4px"}}>《ご予約情報》</p>

                            <ul>
                                <li>
                                    名前 : {trialFlowData.nickname}様
                                </li>
                                <li>
                                    LanCul ID : {trialFlowData.memberCode ?? 0}
                                </li>
                                <li>
                                    予約店舗 : {trialFlowData.storeName} ({trialFlowData.cafeName})
                                </li>
                                <li>
                                    予約日時 : {trialFlowData.displayTime}
                                </li>
                            </ul>

                            <StyledForm
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    requestSetPassword();
                                }}
                            >

                                <div className="input-container">
                                    <label htmlFor="password">パスワード</label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="パスワードを入力してください"
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <div className="input-container">
                                    <label htmlFor="confirm-password">確認用パスワード</label>
                                    <input
                                        type="password"
                                        name="confirm-password"
                                        id="confirm-password"
                                        placeholder="パスワードを入力してください"
                                        required
                                    />
                                </div>

                                <StyledButton
                                    type="submit"
                                    // disabled={!isEnabled}
                                >
                                    パスワードを設定する
                                </StyledButton>
                                {/* <StyledSkipButton
                                    onClick={() => skipSetPassword()}
                                >
                                    Not now
                                </StyledSkipButton> */}
                            </StyledForm>

                        </StyledSuccess>
                        </StyledContent>
                    </>
                )}
        </Page>
        <VersionDisplay />
        </>
    );
}