import { useSearchParams } from "react-router-dom"; 

import Page from "./components/Page";
import VersionDisplay from "./components/VersionDisplay";

import {
    StyledHeader,
    StyledLogoContainer,
    StyledContent,
    StyledSuccessTitle,
    StyledSuccess
} from "./styles/styles";

export default function ReserveSuccess() {

    const [searchParams] = useSearchParams();

    return (
        <>
        <script src="//statics.a8.net/a8sales/a8sales.js"></script>
        <Page>
            <StyledHeader>
            <StyledLogoContainer>
                <a href="https://my-lancul.com/">
                <img src="/logo.png" alt="LanCulロゴ" height="40px" />
                </a>
            </StyledLogoContainer>
            </StyledHeader>

            <StyledContent>
            <StyledSuccessTitle>
                ようこそ！
                <span>WELCOME</span>
            </StyledSuccessTitle>

            <StyledSuccess>
                <p>予約完了まであともう少し！</p>

                <ul>
                    <li>{searchParams.get('fullName') ?? "undefined"}様の</li>
                    <li>
                        LanCul ID: <span id="confirmedMemberCode">{searchParams.get('id') ?? 0}</span>
                    </li>
                </ul>

                <p>
                ご登録のメールアドレスへ確認メールを送信いたしました。
                メールを確認してお手続きを完了させてください。（有効期限：24時間）
                </p>

                <div className="separator">
                <p>確認メールが届かない場合、下記をご確認ください。</p>

                <ul>
                    <li>
                    迷惑メールフォルダにメールが振り分けられている可能性があります。迷惑メールフォルダも含めご確認ください。
                    </li>
                    <li>
                    ドメイン指定受信、メール指定受信など、一部のメールが届かなくなる設定にしていると、確認メールが届かない場合があります。「@lancul.com」からのメールを受信できるように設定してください。
                    </li>
                </ul>
                </div>

                <a
                href="https://faq.lancul.com/knowledge"
                target="_blank"
                rel="noreferrer"
                >
                {">"}よくある質問
                </a>
            </StyledSuccess>
            </StyledContent>
        </Page>
        <VersionDisplay />
        </>
    );
}
