type VersionDisplayProps = {
    enabled?: boolean;
}

export default function VersionDisplay({ enabled = false } : VersionDisplayProps) {
    return (
        <p style={{
            textAlign: "center",
            fontSize: "12px",
            display: enabled ? "block" : "none",
        }}>
            v2.2.1<br/>
            Live API
        </p>
    )
}