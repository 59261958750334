function Api() {

    /* =============================
            PROD API FUNCTIONS
    ============================= */

    const getEvents = async (storeId: string) => {
    
        try {

            const url = 'https://api.lancul.com/memberGetMetadata?' +
                            'itemtype=trialEvents' + '&' +
                            'storeid=' + storeId;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const getBranches = async () => {
        
        const url = 'https://api.lancul.com/memberGetMetadata?' +
                        'itemtype=trialBranches';

        try {
    
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const getBranchAvailablity = async () => {
    
        const url = 'https://api.lancul.com/memberGetMetadata?' +
                        'itemtype=trialBranchAvailability'

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const createOfflineTrial = async (payload: any) => {
    
        const url = 'https://api.lancul.com/memberRegistration';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "registerOfflineTrialNew",
                    ...payload,
                })
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const sendTrialFreeDrinkCoupon = async (payload: any) => {

        const url = 'https://api.lancul.com/memberRegistration';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "sendTrialFreeDrinkCoupon",
                    ...payload,
                })
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const verifyUserAndCheckPasswordInit = async (memberHash : string) => {
    
        const url = 'https://api.lancul.com/memberRegistration';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "verifyUserAndCheckPasswordInit",
                    mh: memberHash,
                })
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const getTrialFlowData = async (memberHash : string) => {
    
        const url = 'https://api.lancul.com/memberRegistration';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "getTrialFlowData",
                    mh: memberHash,
                })
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const setInitialPassword = async (password : string, memberHash : string) => {
    
        const url = 'https://api.lancul.com/memberRegistration';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "setInitialPassword",
                    password,
                    mh: memberHash,
                })
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const recordTrialDrink = async (memberHash : string) => {
    
        const url = 'https://api.lancul.com/memberRegistration';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "recordTrialDrink",
                    mh: memberHash,
                })
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const recordEventTicket = async (memberHash : string) => {
    
        const url = 'https://api.lancul.com/memberRegistration';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "recordEventTicket",
                    mh: memberHash,
                })
            });
    
            const json = await response.json();
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    /* =============================
           TEST/DEV API FUNCTIONS
    ============================= */

    const getEventsTest = async (storeId: string) => {
    
        // const url = 'http://157.245.161.188:3000/test_post?itemtype=trialEvents&storeid=' + storeId;
        const url = 'https://api.lancul.com/memberGetMetadataDev?' +
                        'itemtype=trialEvents' + '&' +
                        'storeid=' + storeId;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
    
            const json = await response.json();
            console.log('Response to getEventsTest call (' + url + '): ' + JSON.stringify(json));
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const getBranchesTest = async () => {
    
        // const url = 'http://157.245.161.188:3000/test_post?itemtype=trialBranches';
        const url = 'https://api.lancul.com/memberGetMetadataDev?' +
                        'itemtype=trialBranches';

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
    
            const json = await response.json();
            console.log('Response to getBranchesTest call (' + url + '): ' + JSON.stringify(json));
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const getBranchAvailablityTest = async () => {
    
        // const url = 'http://157.245.161.188:3000/test_post?itemtype=trialBranches';
        const url = 'https://api.lancul.com/memberGetMetadataDev?' +
                        'itemtype=trialBranchAvailability'

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
    
            const json = await response.json();
            console.log('Response to getBranchesForDateTest call (' + url + '): ' + JSON.stringify(json));
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const createOfflineTrialTest = async (payload: any) => {
    
        // const json = {
        //     statusCode : 0,
        //     statusMessage: "Test status message",
        // };

        // console.log('Mock response (no call made) for createOfflineTrialTest call: ' + JSON.stringify(json));

        // return json;

        // const url = 'http://157.245.161.188:3000/test_post';
        const url = 'https://api.lancul.com/memberRegistrationDev';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "registerOfflineTrialNew",
                    debug: true,
                    ...payload,
                })
            });
    
            const json = await response.json();
            console.log('Real response to createOfflineTrialTest call: ' + JSON.stringify(json));
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const sendTrialFreeDrinkCouponTest = async (payload: any) => {
    
        // const url = 'http://157.245.161.188:3000/test_post';
        const url = 'https://api.lancul.com/memberRegistrationDev';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "sendTrialFreeDrinkCoupon",
                    debug: true,
                    ...payload,
                })
            });
    
            const json = await response.json();
            console.log('Real response to SendTrialFreeDrinkCoupon call: ' + JSON.stringify(json));
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const verifyUserAndCheckPasswordInitTest = async (memberHash : string) => {
    
        // const url = 'http://157.245.161.188:3000/test_post';
        const url = 'https://api.lancul.com/memberRegistrationDev';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "verifyUserAndCheckPasswordInit",
                    mh: memberHash,
                    debug: true,
                })
            });
    
            const json = await response.json();
            console.log('Real response to VerifyUserTest call: ' + JSON.stringify(json));
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const getTrialFlowDataTest = async (memberHash : string) => {
    
//        const url = 'http://157.245.161.188:3000/test_post?action=getUserObject&userid=' + encodeURIComponent(userId);
        const url = 'https://api.lancul.com/memberRegistrationDev';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "getTrialFlowData",
                    mh: memberHash,
                    debug: true,
                })
            });
    
            const json = await response.json();
    
            console.log('Real response to getTrialFlowDataTest call (' + url + '): ' + JSON.stringify(json));
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    const setInitialPasswordTest = async (password : string, memberHash : string) => {
    
//        const url = 'http://157.245.161.188:3000/test_post?action=getUserObject&userid=' + encodeURIComponent(userId);
        const url = 'https://api.lancul.com/memberRegistrationDev';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    action: "setInitialPassword",
                    password,
                    mh: memberHash,
                    debug: true,
                })
            });
    
            const json = await response.json();
    
            console.log('Real response to setInitialPassword call (' + url + '): ' + JSON.stringify(json));
            return json;
    
        } catch (error) {
            console.log(error);
        }
    };

    return {
        getEvents                      : getEvents,
        getBranches                    : getBranches,
        getBranchAvailablity           : getBranchAvailablity,
        createOfflineTrial             : createOfflineTrial,
        SendTrialFreeDrinkCoupon       : sendTrialFreeDrinkCoupon,
        verifyUserAndCheckPasswordInit : verifyUserAndCheckPasswordInit,
        getTrialFlowData               : getTrialFlowData,
        setInitialPassword             : setInitialPassword,
        recordTrialDrink               : recordTrialDrink,
        recordEventTicket              : recordEventTicket,
    };
}

export default Api()