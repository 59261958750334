import * as React from "react";
import styled from "styled-components";

interface FormStepProgressProps {
    steps: string[];
    currentStep: number;
    isSticky?: boolean;
}

interface StyledFormStepProgressContainerProps {
    $isSticky?: boolean;
}

const StyledFormStepProgressContainer = styled.div<StyledFormStepProgressContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    position: sticky;
    top: -1px;
    z-index: 1000;
    background: #FFFFFF;
    padding: 15px 0;
    border-bottom: ${(props) =>
        props.$isSticky ? `1px solid #F75B52` : ""};
`;

const StyledFormStep = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    margin: 0 5px;
    max-width: 150px;
    position: relative;
    background-color: #FFFFFF;
    color: #F75B52;
    border: 1px solid #F75B52;
    transition: 0.3s ease;

    &.active {
        transition: 0.3s ease;
        background-color: #F75B52;
        color: #FFFFFF;
    }

    &:before {
        content: "";
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        right: -15px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #F75B52;

        @media (min-width: 480px) {
            right: -21px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #F75B52;
        }

        @media (min-width: 640px) {
            right: -26px;
        }
    }

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;

        &:before {
        display: none;
        }
    }

    @media (min-width: 480px) {
        margin: 0 10px;
    }

    @media (min-width: 640px) {
        margin: 0 15px;
    }

    span {
        &:first-of-type {
            font-size: 14px;
            text-align: center;

            @media (min-width: 480px) {
                font-size: 16px;
            }
        }

        &:last-of-type {
            font-size: 10px;
            font-weight: 600;
            text-align: center;

            @media (min-width: 480px) {
                font-size: 15px;
            }
        }
    }
`;

const FormStepProgress = React.forwardRef(
  (
    { steps, currentStep, isSticky}: FormStepProgressProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <StyledFormStepProgressContainer $isSticky={isSticky} ref={ref}>
        {steps.map((step, index) => (
          <StyledFormStep
            key={`${step}-${index}`}
            className={index < currentStep ? "active" : ""}
          >
            <span>STEP {index + 1}</span>
            <span>{step}</span>
          </StyledFormStep>
        ))}
      </StyledFormStepProgressContainer>
    );
  }
);

FormStepProgress.displayName = "FormStepProgress";

export default FormStepProgress;
