import styled from "styled-components";

interface StyledMateImageProps {
    src: string;
}


export const StyledButton = styled.button`
    background-color: #F75B52;
    color: #ffffff;
    border: none;
    padding: 10px 50px;
    font-size: 20px;
    border-radius: 50px;
    margin: 50px auto 0px auto;
    display: block;
    transition: 0.3s ease;
    cursor: pointer;

    &:disabled {
        background-color: #b5b5b5;
    }
`;

export const StyledHeader = styled.header`
    padding: 15px;

    @media (min-width: 768px) {
        padding: 30px;
    }
`;

export const StyledHeaderCondensed = styled.header`
    padding: 5px;
    padding-bottom: 0px;
    margin-bottom: -5px;
`;

export const StyledFooter = styled.footer`
    color: #888888;
    padding: 15px;
    font-size: 12px;

    @media (min-width: 768px) {
        padding: 30px;
        font-size: 15px;
    }

    p {
        text-align: center;
    }
`;

export const StyledLogoContainer = styled.div`
    width: 100%;
    max-width: 110px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    h1 {
        font-weight: normal;
        font-size: 30px;
        margin: 0 0 5px;
        line-height: 1;
    }

    span {
        color: #F75B52;
        font-size: 15px;
        line-height: 1;
    }
`;

export const StyledContent = styled.div`
    max-width: 640px;
    margin: 0 auto;

    .form-banner {
        max-width: 100%;
    }
`;

export const StyledEncryptionReminder = styled.p`
    color: #888888;
    font-size: 12px;
    margin: 10px 0;
`;

export const StyledFormHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    border-bottom: 2px solid #e2e2e2;
    padding-bottom: 10px;

    p {
        font-size: 20px;
        position: relative;

        &:after {
        content: "";
        position: absolute;
        background-color: #F75B52;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -12px;
        }
    }

    span {
        font-size: 15px;
        color: #666666;
        span {
        padding: 5px 8px;
        margin: 0 5px;
        background-color: #F75B52;
        color: #ffffff;
        border-radius: 5px;
        font-size: 15px;
        }
    }
`;

export const StyledForm = styled.form`
    margin: 15px 0;

    .input-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
    }

    .input-container select option[value=""] {
        color: #DDD;
    }

    .disabled-option {
        color: #CCC;
    }

    .dual-input {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .input-sub-container {
        display: flex;
        align-items: center;
        width: 100%;

        p {
        margin-right: 10px;
        height: 40px;
        display: flex;
        align-items: center;
        }
    }

    label {
        font-size: 15px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        span {
        display: block;
        color: #F75B52;
        font-size: 10px;
        margin-left: 15px;
        }
    }

    input {
        padding: 5px 10px;
        height: 45px;
        font-size: 15px;
        width: 100%;

        &::placeholder {
            color: #b3b3b3;
        }
    }

    select {
        height: 40px;
        padding: 5px;
        font-size: 15px;
        width: 100%;
    }

    option {
        color: #666666;
    }

    textarea {
        min-width: 100%;
        max-width: 100%;

        &::placeholder {
        color: #b3b3b3;
        }
    }

    .checkbox-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        input {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        }

        div {
        line-height: 1.1;
        }
    }
`;

export const StyledEventSelect = styled.div`
    margin: 15px 0 25px;
    padding: 15px;
    border: 1px solid #333333;
    border-radius: 4px;
    max-height: 450px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-color: rgb(133, 133, 133);

    .event-warning {
        font-weight: bold;
    }

    .event-container {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        background-color: #FFFFFF;
        border: 1px solid rgb(133, 133, 133);
        border-radius: 4px;
        padding: 15px;
        min-height: 100px;
        overflow-x: hidden;
        position: relative;

        .event-info {
        display: flex;
        align-items: center;
        }

        .event-date {
        display: flex;
        flex-direction: column;

        span {
            &:first-of-type {
            font-size: 12px;
            }
        }
        }

        .event-mate {
        display: flex;
        align-items: center;
        margin-left: 5px;

        @media (min-width: 640px) {
            margin-left: 25px;
        }
        }

        .event-tags {
        display: flex;
        align-items: center;
        }

        .event-recommended {
        font-size: 9px;
        position: absolute;
        background-color: #fcc03f;
        width: 80px;
        min-height: 50px;
        top: -20px;
        right: -35px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 5px 0;
        transform: rotateZ(45deg);
        }

        input {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        }
    }
`;

export const StyledMateImage = styled.div<StyledMateImageProps>`
    border-radius: 50%;
    margin: 0 10px;
    width: 75px;
    height: 75px;
    background: url(${(props) => props.src ?? ""});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

export const StyledCompanyInfo = styled.div`
    margin: 60px 0px 10px 0px;
    padding: 15px;

    // background-color: #fff9f9;
    // border: 3px solid #ffe1e1;
    background-color: #eee;
    border: 3px solid #D3D3D3;

    ul {
        list-style-type: none;
        margin-top: 15px;

        li {
            margin: 5px 25px;
            position: relative;

            &:before {
                position: absolute;
                left: -15px;
                top: 10px;
                width: 15px;
                height: 15px;
                transform: translate(-50%, -50%);
                filter: grayscale(100%);
            }

            &:first-of-type:before {
                content: url("https://my-lancul.com/members/images/icon-web.svg");
            }

            &:nth-of-type(2):before {
                content: url("https://my-lancul.com/members/images/icon-tell.svg");
            }

            &:last-of-type:before {
                content: url("/icon_faq.png");
            }
        }

        a {
            // color: #F75B52;
            color: #585858;
            text-decoration: none;
        }
    }

    h3 {
        margin-bottom: 16px;
    }

    div {
        display: flex;
        align-items: center;
    }

    img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        margin-top: -4px;
    }

    a {
        // color: #F75B52;
        color: #585858;
        text-decoration: none;
    }
`;

export const StyledDialogContent = styled.div`
    h2 {
        margin: 25px 0;
    }

    table {
        margin: 25px 0;
        list-style-type: none;
        width: 100%;

        td {
            padding: 6px 0;
        }

        .property-name {
            font-weight: 600;
            min-width: 70px;
            display: inline-block;
        }

        .property-delim {
            font-weight: 600;
            display: inline-block;
        }

        .property-value {
            margin-left: 15px;
            max-width: 60%;
            display: inline-block;
            word-wrap: break-word;
        }

    }

    .dialog-button {
        margin-top: 20px;
        width: 100%;
    }

    .back-button {
        background-color: #888;
    }

    .dialog-button-container {
        display: flex;
        gap: 5%;
    }
`;

export const StyledSuccessTitle = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 15px 0;

    span {
        font-size: 18px;
        color: #F75B52;
    }
`;

export const StyledSuccess = styled.div`
    p {
        margin: 30px 0;
    }

    & > ul {
        padding-left: 10px;
        border-left: 2px solid #c5c4c4;
        list-style-type: none;

        li {
            margin: 10px 0;
            font-size: 16px;
            display: flex;
            flex-direction: column;

            span {
                font-size: 32px;
                color: #F75B52;
            }
        }
    }

    .separator {
        border-top: 1px solid #c5c4c4;

        ul {
        padding: 15px;
        background-color: #f3f3f3;

        li {
            margin: 15px;

            @media (min-width: 640px) {
            margin: 30px;
            }
        }
        }
    }

    & > a {
        outline: 0;
        border: 0;
        background-color: transparent;
        font-size: 20px;
        margin: 30px auto;
        display: block;
        text-align: center;
        color: #F75B52;
        letter-spacing: 2px;
        cursor: pointer;
        text-decoration: none;
    }
`;

export const StyledTrialActionButton = styled.div`
    width: 80%;
    background: #F75B52;
    height: 75px;
    margin: 50px auto;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    color: white;
`;

export const StyledSkipButton = styled.button`
    background-color: white;
    color: #777;
    border: none;
    font-size: 20px;
    margin: 10px auto;
    display: block;
    transition: 0.3s ease;
    cursor: pointer;
    font-size: 16px;

    &:disabled {
        background-color: #b5b5b5;
    }
`;
