import styled from "styled-components";

type PageProps = {
    children?: React.ReactNode;
};

const StyledPage = styled.div`
    padding: 15px;

    @media (min-width: 768px) {
        padding: 30px;
    }
`;

export default function Page({ children }: PageProps) {
    return <StyledPage>{children}</StyledPage>;
}