import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom"; 
import QRCode from 'react-qr-code';

import Page from "./components/Page";
import VersionDisplay from "./components/VersionDisplay";
import CouponDialog from "./components/CouponDialog";
import api from './api/api';

import {
    StyledHeaderCondensed,
    StyledLogoContainer,
    StyledContent,
    StyledSuccessTitle,
    StyledSuccess,
    StyledTrialActionButton,
    StyledCompanyInfo
} from "./styles/styles";

interface TrialDataProps {
    [key: string]: any;
}

export default function TrialStart() {

    const [searchParams] = useSearchParams();
    const [showCoupon, setShowCoupon] = useState(false);
    const [showCheckIn, setShowCheckIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [trialFlowData, setTrialFlowData] = useState<TrialDataProps>({});
    const [errorMsg, setErrorMsg] = useState("");

    const memberHash = searchParams.get('mh') ?? "";

    useEffect(() => {

        async function getTrialFlowData() {

            const resp = await api.getTrialFlowData(memberHash);

            if (resp.statusCode === 1) {
                setErrorMsg("無料体験の再予約がまだのようです。希望日時・店舗をカスタマーサポートまでお問い合わせください。");
                return;
            }

            resp.displayTime = resp.startTime + " - " + 
                (
                    resp.endTime && resp.endTime.split(' ').length > 0 ?
                        resp.endTime.split(' ')[1] :
                        resp.endTime
                );

            setTrialFlowData(resp);
            setIsLoading(false);
        }
        getTrialFlowData();

    }, []);

    const didClickShowCoupon = async () => {

        setShowCoupon(true);
        await api.recordTrialDrink(memberHash);
    };

    const didClickShowCheckIn = async () => {

        setShowCheckIn(true);
        await api.recordEventTicket(memberHash);
    };

    return (
        <>
        <script src="//statics.a8.net/a8sales/a8sales.js"></script>

        {showCoupon && (
            <CouponDialog hide={() => setShowCoupon(false)}>
                <img src="/drink-success.png" />
                <span className="drink-notif">体験当日にご利用いただけます</span>
                <span className="drink-claim-details">{trialFlowData.storeName} ({trialFlowData.cafeName})</span>
                <span className="drink-claim-details">{trialFlowData.displayTime}</span>
            </CouponDialog>
        )}

        {showCheckIn && (
            <CouponDialog hide={() => setShowCheckIn(false)}>
                <QRCode
                    className="qr-code"
                    value={trialFlowData.ticketCode}
                />
                <p>英会話テーブルにいる外国人スタッフに提示してチェックインしてください</p>
            </CouponDialog>
        )}

        <Page>
            <StyledHeaderCondensed>
            <StyledLogoContainer>
                <a href="https://my-lancul.com/">
                <img src="/logo.png" alt="LanCulロゴ" height="40px" />
                </a>
            </StyledLogoContainer>
            </StyledHeaderCondensed>

            <StyledContent>

            {isLoading ? (

                errorMsg && errorMsg.length > 0 ? (
                    <>
                        <p style={{textAlign : 'center', margin : '20px'}}>{errorMsg}</p>
                        <StyledCompanyInfo>
                            <h3>お問い合わせ</h3>

                            <div>
                                <img src="/icon_faq.png" />
                                <a
                                    href="https://faq.lancul.com/knowledge"
                                    target="_blank"
                                    rel="noreferrer"
                                > 
                                        よくあるご質問
                                </a>
                            </div>
                        </StyledCompanyInfo>
                    </>
                ) : (
                    <div
                        className="loading"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginTop: "1.5rem",
                        }}
                        id="loading"
                    >
                    <img src="/spinner.gif" alt="Loading..." height="40px" />
                    <br />
                    <strong>しばらくお待ちください</strong>
                    </div>
                )
            ) : (

                <>
                <StyledSuccessTitle>
                    WELCOME!
                    <span>ランカルの無料体験へようこそ！</span>
                </StyledSuccessTitle>

                <p style={{"textAlign": "center"}}>
                    準備が整いました！あとは当日参加するだけ<span style={{"fontFamily":"-apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto"}}>♪</span><br />
                    （予約情報はページ下部で確認できます。）
                </p>

                <StyledSuccess>
                    <StyledTrialActionButton
                        onClick={() => didClickShowCheckIn()}
                    >
                        ①チェックインする
                    </StyledTrialActionButton>
                    <StyledTrialActionButton
                        onClick={() => didClickShowCoupon()}
                    >
                        ②ドリンクを受け取る
                    </StyledTrialActionButton>

                    <p style={{margin: "10px 0px"}}>《当日の流れ》</p>
                    予約した店舗に着いたら「ランカルの体験で来ました…」とスタッフに伝えると英会話カフェのテーブルを案内してくれます！<br/>
                    続いて①チェックインをしたら、②ドリンクを受け取るだけ！あとはリラックスして会話に参加しましょう<span style={{"fontFamily":"-apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto"}}>♪</span><br/>
                    <p style={{marginTop: "20px", marginBottom: "-4px"}}>《ご予約情報》</p>
                    <ul>
                        <li>
                            名前 : {trialFlowData.nickname}様
                        </li>
                        <li>
                            LanCul ID : {trialFlowData.memberCode ?? 0}
                        </li>
                        <li>
                            予約店舗 : {trialFlowData.storeName} {"(" + trialFlowData.cafeName + ")"}
                        </li>
                        <li>
                            予約日時 : {trialFlowData.displayTime}
                        </li>
                    </ul>
                </StyledSuccess>
                </>
            )}

            </StyledContent>
        </Page>
        <VersionDisplay />
        </>
    );
}